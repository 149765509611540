<template>
  <div style="width: 100%; margin: 0; padding: 0">
    <header class="meeting-head">
      <div style="
          width: 60px;
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
        " @click="goBack">
        <img src="../../assets/icon_back.png" style="width: 27px; height: 27px; object-fit: cover" />
      </div>
      <p>{{ meeting_id == "" ? "新增会议" : meetingInfo.name }}</p>
    </header>
    <el-container>
      <el-aside width="234px" class="meeting-aside">
        <div v-if="user_type == 'organizer' || user_type == 'admin'" style="flex: 1;overflow-y: auto;">
          <el-menu>
            <div v-for="(tab, index) in tabs" :key="index" @click="handleTabChanged(index)">
              <el-submenu v-if="tab.children && tab.children.length > 0" style="padding-left: 0px;"
                :index="(index + 1)">
                <template slot="title">
                  <div :class="tab.selected ? 'tab-item tab-item-selected' : 'tab-item'">
                    <img :src="tab.selected ? tab.selectedIcon : tab.unSelectedIcon" class="tab-icon" />
                    <p>{{ tab.name }}</p>
                  </div>
                </template>
                <el-menu-item v-for="(item, itemIndex) in tab.children" :key="itemIndex"
                  :index="(index + 1) + '-' + (itemIndex + 1)">
                  <template slot="title">
                    <div :class="item.selected ? 'tab-item tab-item-selected' : 'tab-item'" style="padding-left: 30px;"
                      @click.stop="handleSubMenuChanged(index, itemIndex)">
                      <img :src="item.selected ? item.selectedIcon : item.unSelectedIcon" class="tab-icon" />
                      <p>{{ item.name }}</p>
                    </div>
                  </template>
                </el-menu-item>
              </el-submenu>
              <el-menu-item v-else style="padding-left: 0px;" :index="(index + 1)">
                <template slot="title">
                  <div :class="tab.selected ? 'tab-item tab-item-selected' : 'tab-item'">
                    <img :src="tab.selected ? tab.selectedIcon : tab.unSelectedIcon" class="tab-icon" />
                    <p>{{ tab.name }}</p>
                  </div>
                </template>
              </el-menu-item>
            </div>
          </el-menu>
        </div>
        <div v-if="user_type == 'organizer' || user_type == 'admin'" class="tab-item tab-item-selected" style="color: red;" @click="doRemoveMeeting">
          <img src="../../assets/icon_meeting_delete_red.png" class="tab-icon" />
          <p>{{ '删除会议 ' }}</p>
        </div>
        <div v-if="user_type == 'partner'">
          <div v-for="(tab, index) in partner_tabs" :key="index"
            :class="tab.selected ? 'tab-item tab-item-selected' : 'tab-item'" @click="handlePartnerTabChanged(index)">
            <img :src="tab.selected ? tab.selectedIcon : tab.unSelectedIcon" class="tab-icon" />
            <div>{{ tab.name }}</div>
          </div>
        </div>
      </el-aside>
      <el-main>
        <el-container style="height: calc(100vh - 64px); overflow-y: auto">
          <!-- <keep-alive> -->
          <router-view></router-view>
          <!-- </keep-alive> -->
        </el-container>
      </el-main>
    </el-container>

    <el-dialog :visible.sync="showMeetingRemindDialog" title="会议通知设置" width="45%">
      <div>
        <el-form>
          <el-form-item label="">
            <el-checkbox v-model="meetingRemindInfo.after_register" label="注册完成后提醒"></el-checkbox>
          </el-form-item>
          <el-form-item label="">
            <el-checkbox v-model="meetingRemindInfo.before_one_day" label="会议开始前一天提醒"></el-checkbox>
          </el-form-item>
          <el-form-item label="">
            <el-checkbox v-model="meetingRemindInfo.before_one_hour" label="会议开始前一小时提醒"></el-checkbox>
          </el-form-item>
        </el-form>

        <div style="text-align: right">
          <span slot="footer" class="dialog-footer">
            <el-button @click="showMeetingRemindDialog = false">取 消</el-button>
            <el-button type="primary" @click="saveMeetingRemind()">保存</el-button>
          </span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getMeetingInfo,
  updateMeetingRemind,
} from "../../api/api";
import {
  getUserType,
  setMeetingTabIndex,
  getMeetingTabIndex,
} from "../../utils/store";
export default {
  name: "index",
  data() {
    return {
      showMeetingRemindDialog: false,
      meeting_id: this.$route.query.meeting_id,
      org_id: this.$route.query.org_id,
      meetingInfo: {},
      user_type: getUserType(),
      tabs: [
        {
          name: "信息编辑",
          path: "/meeting/edit",
          selected: true,
          selectedIcon: require("../../assets/icon_meeting_edit_select.png"),
          unSelectedIcon: require("../../assets/icon_meeting_edit_normal.png"),
          children: [
            {
              name: "联系方式",
              path: "/meeting/contacts",
              selected: false,
              selectedIcon: require("../../assets/icon_meeting_contacts_select.png"),
              unSelectedIcon: require("../../assets/icon_meeting_contacts_normal.png"),
            }
          ]
        },
        {
          name: "日程列表",
          path: "/meeting/session",
          selected: false,
          selectedIcon: require("../../assets/icon_meeting_session_select.png"),
          unSelectedIcon: require("../../assets/icon_meeting_session_normal.png"),
          children: [
            // {
            //   name: "日程列表",
            //   path: "/meeting/session",
            //   selected: false,
            //   selectedIcon: require("../../assets/icon_meeting_session_select.png"),
            //   unSelectedIcon: require("../../assets/icon_meeting_session_normal.png"),
            // },
            {
              name: "日程组",
              path: "/meeting/sessionGroup",
              selected: false,
              selectedIcon: require("../../assets/icon_meeting_sessionGroup_select.png"),
              unSelectedIcon: require("../../assets/icon_meeting_sessionGroup_normal.png"),
            },
            {
              name: "参会嘉宾",
              path: "/meeting/guests",
              selected: false,
              selectedIcon: require("../../assets/icon_meeting_guests_select.png"),
              unSelectedIcon: require("../../assets/icon_meeting_guests_normal.png"),
            },
            {
              name: "会议房间",
              path: "/meeting/rooms",
              selected: false,
              selectedIcon: require("../../assets/icon_meeting_rooms_select.png"),
              unSelectedIcon: require("../../assets/icon_meeting_rooms_normal.png"),
            },
          ]
        },
        {
          name: "文件管理",
          path: "/meeting/fileManage",
          selected: true,
          selectedIcon: require("../../assets/icon_meeting_handbook_select.png"),
          unSelectedIcon: require("../../assets/icon_meeting_handbook_normal.png"),
          children: [
          ]
        },
        {
          name: "品牌客户",
          path: "/meeting/partners",
          selected: false,
          selectedIcon: require("../../assets/icon_meeting_partners_select.png"),
          unSelectedIcon: require("../../assets/icon_meeting_partners_normal.png"),
          children: [
            {
              name: "会议展商",
              path: "/meeting/exhibitors",
              selected: false,
              selectedIcon: require("../../assets/icon_meeting_exhibitors_select.png"),
              unSelectedIcon: require("../../assets/icon_meeting_exhibitors_normal.png"),
            },
            {
              name: "品牌产品",
              path: "/meeting/partner-product",
              selected: false,
              selectedIcon: require("../../assets/icon_meeting_exhibitors_select.png"),
              unSelectedIcon: require("../../assets/icon_meeting_exhibitors_normal.png"),
            }
          ]
        },
        // {
        //   name: "赞助产品",
        //   path: "/meeting/sponsorContents",
        //   selected: false,
        //   selectedIcon: require("../../assets/icon_meeting_sponsorcontents_select.png"),
        //   unSelectedIcon: require("../../assets/icon_meeting_sponsorcontents_normal.png"),
        // },
        // {
        //   name: "会议展商",
        //   path: "/meeting/exhibitors",
        //   selected: false,
        //   selectedIcon: require("../../assets/icon_meeting_exhibitors_select.png"),
        //   unSelectedIcon: require("../../assets/icon_meeting_exhibitors_normal.png"),
        // },

        {
          name: "通知设置",
          path: "/meeting/remind",
          selected: false,
          selectedIcon: require("../../assets/icon_meeting_remind_select.png"),
          unSelectedIcon: require("../../assets/icon_meeting_remind_normal.png"),
        },
        {
          name: "咨询统计",
          path: "/meeting/statistics",
          selected: false,
          selectedIcon: require("../../assets/icon_meeting_remind_select.png"),
          unSelectedIcon: require("../../assets/icon_meeting_remind_normal.png"),
        },
        // {
        //   name: "会议手册",
        //   path: "/meeting/handbooks",
        //   selected: false,
        //   selectedIcon: require("../../assets/icon_meeting_handbook_select.png"),
        //   unSelectedIcon: require("../../assets/icon_meeting_handbook_normal.png"),
        // },
        {
          name: "与会人员",
          path: "/meeting/participants",
          selected: false,
          selectedIcon: require("../../assets/icon_meeting_participant_select.png"),
          unSelectedIcon: require("../../assets/icon_meeting_participant_normal.png"),
          children: [
            {
              name: "门票管理",
              path: "/meeting/tickets",
              selected: false,
              selectedIcon: require("../../assets/icon_meeting_ticket_select.png"),
              unSelectedIcon: require("../../assets/icon_meeting_ticket_normal.png"),
            },
            {
              name: "客服消息",
              path: "/meeting/message",
              selected: false,
              selectedIcon: require("../../assets/kefuSelect.png"),
              unSelectedIcon: require("../../assets/kefu.png"),
            },
          ]
        },
        // {
        //   name: "删除会议",
        //   path: "/meeting/delete",
        //   selected: false,
        //   selectedIcon: require("../../assets/icon_meeting_delete_red.png"),
        //   unSelectedIcon: require("../../assets/icon_meeting_delete_red.png"),
        // },
      ],
      partner_tabs: [
        {
          name: "日程列表",
          path: "/meeting/session",
          selected: false,
          selectedIcon: require("../../assets/icon_meeting_session_select.png"),
          unSelectedIcon: require("../../assets/icon_meeting_session_normal.png"),
        },
        {
          name: "推广产品",
          path: "/meeting/guests",
          selected: false,
          selectedIcon: require("../../assets/icon_meeting_guests_select.png"),
          unSelectedIcon: require("../../assets/icon_meeting_guests_normal.png"),
        },
        {
          name: "参会嘉宾",
          path: "/meeting/guests",
          selected: false,
          selectedIcon: require("../../assets/icon_meeting_guests_select.png"),
          unSelectedIcon: require("../../assets/icon_meeting_guests_normal.png"),
        },
        {
          name: "客服消息",
          path: "/meeting/message",
          selected: false,
          selectedIcon: require("../../assets/kefuSelect.png"),
          unSelectedIcon: require("../../assets/kefu.png"),
        },

      ],
      currentTab: 0,
      meetingRemindInfo: {
        meeting_id: "",
        after_register: "",
        before_one_day: "",
        before_one_hour: "",
      },
    };
  },
  mounted() {
    const { org_id, meeting_id } = this.$route.query
    const cacheOrgId = localStorage.getItem('CUR_ORGID')
    if (org_id) {
      localStorage.setItem('CUR_ORGID', org_id)
      this.org_id = org_id
    } else if (cacheOrgId) {
      this.org_id = cacheOrgId
    }

    const cacheMeetingId = localStorage.getItem('CUR_MEETING_ID')
    if (meeting_id) {
      localStorage.setItem('CUR_MEETING_ID', meeting_id)
      this.meeting_id = meeting_id
    } else if (cacheMeetingId) {
      this.meeting_id = cacheMeetingId
    }
    this.currentTab = getMeetingTabIndex();
    if (!this.currentTab) {
      this.currentTab = 0;
    }
    if (this.meeting_id == "") {
      this.currentTab = 0;
    }

    this.$meeting.$on("handleMeetingChanged", (res) => {
      this.meeting_id = res;
      this.fetchData();
    });
    console.log(this.user_type);
    this.fetchData();
    if ((this.meeting_id || this.org_id) && (this.user_type == 'organizer' || this.user_type == 'admin')) {
      this.handleTabChanged(this.currentTab);
    } else if (this.user_type == 'partner') {
      this.handlePartnerTabChanged(this.currentTab);
    }

  },
  beforeDestroy() {
    //不能这里面写
  },
  methods: {


    handleSubMenuChanged(tabIndex, subMenuIndex) {
      for (let nav of this.tabs) {
        nav.selected = false
      }
      let children = this.tabs[tabIndex].children
      for (let i = 0; i < children.length; i++) {
        children[i].selected = false
      }
      children[subMenuIndex].selected = true
      let path = children[subMenuIndex].path
      this.$router.push({
        path: path,
        query: {
          meeting_id: this.meeting_id,
        },
      });
    },

    saveMeetingRemind() {
      updateMeetingRemind(this.meetingRemindInfo).then((res) => {
        this.$message.success("保存成功");
        this.fetchData();
        this.showMeetingRemindDialog = false;
      });
    },

    fetchData() {
      if (this.meeting_id == "") {
        return;
      }
      getMeetingInfo(this.meeting_id).then((res) => {
        let result = res.data.data;
        this.meetingInfo = result;
        this.meetingRemindInfo = {
          meeting_id: result._id.$id,
          after_register: result.remind ? result.remind.after_register : false,
          before_one_day: result.remind ? result.remind.before_one_day : false,
          before_one_hour: result.remind
            ? result.remind.before_one_hour
            : false,
        };
      });
    },
    doRemoveMeeting() {
      this.$alert("您确定要删除此会议？", "提示", {
        callback: (action) => {
          if (action == "confirm") {
            // removeMeeting(this.meeting_id).then((res) => {
            //     this.$message.success('删除成功')
            // })
            this.$message.success("你没有权限");
          }
        },
      });
    },
    handleTabChanged(index) {
      if (this.meeting_id == "add" && index != 0) {
        return "";
      }
      if (this.user_type == 'organizer' && this.tabs[index].name == "删除会议") {
        this.$alert("您确定要删除此会议？", "提示", {
          callback: (action) => {
            if (action == "confirm") {
              // removeMeeting(this.meeting_id).then((res) => {
              //     this.$message.success('删除成功')
              // })
              this.$message.success("你没有权限");
            }
          },
        });
        return;
      }
      // if (this.user_type == 'organizer' && this.tabs[index].name == "通知设置") {
      //   this.showMeetingRemindDialog = true;
      //   return;
      // }
      for (let i = 0; i < this.tabs.length; i++) {
        this.tabs[i].selected = false;
        if (this.tabs[i].children && this.tabs[i].children.length > 0) {
          for (let chid of this.tabs[i].children) {
            chid.selected = false
          }
        }
      }
      this.tabs[index].selected = true;
      this.currentTab = index;
      let path = this.tabs[index].path;
      this.$router.push({
        path: path,
        query: {
          meeting_id: this.meeting_id,
        },
      });
      setMeetingTabIndex(index);
    },
    handlePartnerTabChanged(index) {
      for (let i = 0; i < this.partner_tabs.length; i++) {
        this.partner_tabs[i].selected = false;
      }
      this.partner_tabs[index].selected = true;
      this.currentTab = index;
      let path = this.partner_tabs[index].path;
      this.$router.push({
        path: path,
        query: {
          meeting_id: this.meeting_id,
        },
      });
      setMeetingTabIndex(index);
    },
    goBack() {
      setMeetingTabIndex(0);
      let user_type = getUserType();
      if (user_type == "admin") {
        this.$router.replace({
          path: "/home/meetings",
          query: {
            id: this.org_id,
          },
        });
        return;
      } else if (user_type == 'partner') {
        this.$router.replace({
          path: "/home/partner_meetings",
        });
        return;
      }else if(user_type == 'organizer'){
        this.$router.replace({
          path: "/home/tabsList",
        });
        return;
      }
      this.$router.replace("/home/meetings");
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep(.el-submenu) .el-menu-item {
  padding: 0px 0px !important;
}

::v-deep(.el-submenu__title) {
  padding: 0px !important;
  padding-left: 0px !important;
}

::v-deep(.el-submenu__title:hover) {
  background-color: transparent !important;
}

::v-deep(.el-menu-item:hover) {
  background-color: transparent !important;
}

::v-deep(.el-menu-item) {
  border-color: transparent;
  background-color: transparent !important;
}

::v-deep(.el-menu) {
  border-color: transparent;
  background-color: transparent;
}

::v-deep(.el-menu:hover) {
  border-color: transparent;
  background-color: transparent;
}

::-webkit-scrollbar {
  display: none;
}

.meeting-aside {
  padding: 20px 20px 20px 0px;
  width: 100%;
  box-sizing: border-box;
  background-color: #0080ff;
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;


  .tab-item {
    cursor: pointer;
    display: flex;
    width: 100%;
    height: 60px;
    align-items: center;
    color: white;
    font-size: 14px;
    background-color: transparent;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    box-sizing: border-box;
    padding-left: 20px;
  }

  .tab-item-selected {
    background-color: #65b3ff;
  }

  .tab-icon {
    margin-right: 10px;
    width: 25px;
    height: auto;
    object-fit: cover;
  }
}

.meeting-content {
  width: 100%;
  height: calc(100vh - 64px);
  overflow-y: auto;
}

.meeting-head {
  padding-left: 20px;
  box-sizing: border-box;
  display: flex;
  height: 64px;
  align-items: center;
  position: relative;
  background-color: white;
  color: #242f57;
  font-size: 24px;
  line-height: 64px;
  border-bottom: 1px #dcdcdc solid;
}

.el-main {
  padding: 0;
}
</style>
